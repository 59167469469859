$(document).ready(function() {
	var backToTopButton = $("#back-to-top");

	$(window).scroll(function() {
		if ($(window).scrollTop() > 300) {
			backToTopButton.addClass("show");
		} else {
			backToTopButton.removeClass("show");
		}
	});

	backToTopButton.on("click", function() {
		$("html, body").animate({
			scrollTop: 0
		}, "500");
	});
});
